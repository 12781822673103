:root {
  --red: #ff3e8f;
  --green: #27ec07;
  --darkRed: #ce0157;
  --darkGreen: #128400;
}

.map {
  background-image: url("./bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 10px 0 20px;
}

.logo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.logo-header > img {
  max-width: 100%;
}
.logo-header > img:first-child {
  height: 50px;
}
.logo-header > img:last-child {
  height: 80px;
}
.align-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 15px;
  margin: 35px auto 15px;
}
.align-flex > h2 {
  margin: 0px;
}
.channel {
  position: relative;
  display: flex;
  align-items: center;
}
.channel > h2 {
  margin-right: 0.5em;
}
.channel > select {
  display: inline-block;
  outline: none;
  height: 30px;
  padding: 4px 8px;
  margin: 0 10px;
  font-size: 14px;
  line-height: 1;
  color: #555;
  background-color: #fff;
  background-image: none;

  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.toggle-btn {
  display: flex;
  align-items: center;
}
.toggle-btn > .switch {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  width: 56px;
  height: 30px;
}
.toggle-btn > .switch > input[type="checkbox"] {
  opacity: 0;
  /* width: 0;
  height: 0; */
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.toggle-btn > .switch > span {
  position: absolute;
  border-radius: 34px;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}
.toggle-btn > .switch > span:before {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  background-color: #fff;
}
.toggle-btn > .switch > input:checked + span {
  background-color: #ff630b;
}
.toggle-btn > .switch > input:focus + span {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
}
.toggle-btn > .switch > input:checked + span::before {
  transform: translateX(26px);
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
}
.googleMap {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin: 0 20px;
  padding: 70px 40px 30px;
  width: 100%;
  height: 560px;
}
.googleMap > .location {
  width: 100%;
  height: 550px;
}

.image1 {
  height: 150px;
  width: 550px;
  border: 1px solid #037873;
  background-color: #037873;
  margin: 0px auto 0px;
}
.image2 {
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
  padding: 15px;
  width: 550px;
  height: 560px;
  background-color: white;
  margin: 0px auto 15px;
}
.m {
  width: 100%;
  height: 705px;
  background-image: url(images/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

/* ------------------------------------------------------------------------------------------------- */

.page-bg {
  background-image: url(images/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 1em;
  min-height: 100%;
}
.page-header {
  display: flex;
  align-items: center;
  padding: 1em;
  justify-content: space-between;
}
.page-header > img {
  height: 100px;
  max-width: 100%;
}
.page-header > img:first-child {
  height: 70px;
}
.page-header > h2 {
  color: #fff;
  margin: 0px;
}
.emp-grid {
  background-color: rgb(255 255 255 / 25%);
  border-radius: 15px;
  margin: auto 1em;
  padding: 1em;
}
.emp-grid > .bordergrid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.profile-widget {
  margin-bottom: 15px;
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.profile-widget > .emp-img {
  border-radius: 50%;
  height: 65px;
  position: relative;
  width: 65px;
  padding: 1px;
  border: 2px solid #fff;
}
.profile-widget.status > .emp-img::before {
  content: "";
  position: absolute;
  z-index: 1;
  height: 7px;
  width: 7px;
  line-height: 7px;
  border-radius: 50%;
  background-color: #000;
  top: 0px;
  right: 8px;
}
.profile-widget > .emp-img > img {
  width: 60px;
  border-radius: 50%;
  height: 60px;
}
.profile-widget > .emp-name {
  background-color: transparent;
  color: #333;
  padding: 10px;
  padding-left: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0px;
  margin-bottom: 0px;
  flex-grow: 1;
  margin-left: -15px;
  border-radius: 0 50px 50px 0;
}
.profile-widget.bg-red > .emp-img,
.profile-widget.bg-red:hover > .emp-img {
  border-color: var(--red);
}

.profile-widget.bg-red:hover > .emp-name {
  background-color: var(--red);
}
.profile-widget.bg-red > .emp-img:before,
.profile-widget.bg-red:hover > .emp-img:before {
  background-color: var(--darkRed);
}
.profile-widget.bg-green > .emp-img,
.profile-widget.bg-green:hover > .emp-img {
  border-color: var(--green);
}

.profile-widget.bg-green:hover > .emp-name {
  background-color: var(--green);
}
.profile-widget.bg-green > .emp-img:before,
.profile-widget.bg-green:hover > .emp-img:before {
  background-color: var(--darkGreen);
}
.popUp {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.popUp-content {
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  margin: 15% auto;
  border-radius: 15px;
  padding: 15px;
  border: 1px solid #888;
  width: 300px;
}

.popDown {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  float: right;
  font-size: 28px;
  min-width: 28px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
}

.popDown:hover,
.popDown:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.popUp-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.popUp-heading > h2 {
  margin: auto;
}

.popUp-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.popUp-body > .profile {
  text-align: center;
  border-right: 1px solid #ddd;
  padding: 0 1em;
}

.popUp-body > .profile > h5 {
  font-size: 15px;
  margin: 0px;
  font-weight: 400;
  white-space: nowrap;
}

.popUp-body > .profile > img {
  width: 8rem;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  padding: 4px;
}

.popUp-body > .infos {
  padding: 0 1em;
}

ul.personal-info {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.personal-info > li {
  display: flex;
  align-items: center;
}
ul.personal-info > li:not(:last-child) {
  margin-bottom: 10px;
}
ul.personal-info > li > .title,
ul.personal-info > li > .text {
  flex: 1;
}
ul.personal-info > li > .title {
  font-weight: 400;
}
ul.personal-info > li > .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (min-width: 768px) {
  .popUp-content {
    width: 40%;
  }
  .popUp-body > .infos {
    flex: 75%;
  }
  .emp-grid > .bordergrid > .grid {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (max-width: 768px) {
  .emp-grid > .bordergrid > .grid {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.emp-grid > .bordergrid:not(:last-child) {
  margin-bottom: 10px;
  border-bottom: 1px solid white;
}





/* Loginpage Css */

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}
